import logo from './logo_clipping.svg';
import DownloadAppStore from './baixar-app-store.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="Description">
          Tenha na palma de sua mão as principais e mais importantes notícias da região.
          Fique bem informado, de maneira rápida e eficaz, para ter um dia produtivo.
        </p>
        <div className='Download-wrapper'>
          <a href='https://play.google.com/store/apps/details?id=com.fuzalabs.clipping&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className='Android-img' alt='Disponível no Google Play' src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt_badge_web_generic.png' /></a>
          <a href="https://apps.apple.com/br/app/clipping-online/id1622280352"><img className='iOS-img' src={DownloadAppStore} alt="Baixar na App Store" /></a>
        </div>
      
      
      </header>
    </div>
  );
}

export default App;
